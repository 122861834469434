import * as React from "react";
import { AxiosInstance } from "axios";
import { useState } from "react";
import {
  makeStyles,
  useId,
  Button,
  Input,
  Subtitle2,
  Title3,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { DismissCircle16Filled } from "@fluentui/react-icons";

/* global console */

interface NewProjectFormProps {
  axios: AxiosInstance;
  authHeader: string;
  onSubmitClick: () => {};
  onCancelClick: () => {};
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
    // Stack the label above the field (with 2px gap per the design system)
    "> div": {
      display: "flex",
      flexDirection: "column",
      marginBottom: "20px",
    },
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },
});

const NewProjectForm: React.FC<NewProjectFormProps> = ({
  axios,
  authHeader,
  onSubmitClick,
  onCancelClick,
}) => {
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null | undefined>(
    undefined
  );
  const [endDate, setEndDate] = useState<Date | null | undefined>(undefined);

  const styles = useStyles();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const projectsResponse = await axios.post(
        "/v1/microsoft_outlook/project",
        JSON.stringify({
          id: id,
          name: name,
          description: description,
          addresses: [
            {
              address: address,
              address_components: {
                postal_code: postalCode,
                street_address: address,
              },
            },
          ],
          start_date: startDate ? startDate.toISOString() : undefined,
          end_date: endDate ? endDate.toISOString() : undefined,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader,
          },
        }
      );
      console.log("Response: " + projectsResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const projectIdId = useId("content-project-id");
  const projectNameId = useId("content-project-name");
  const projectAddressId = useId("content-project-address");
  const projectDescriptionId = useId("content-project-description");
  const projectStartDateId = useId("content-project-start-date");
  const projectEndDateId = useId("content-project-end-date");

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.header}>
        <Title3>Neues Projekt</Title3> {/* TODO(databurro): i18n */}
        <Button
          appearance="transparent"
          size="small"
          onClick={onCancelClick}
          icon={<DismissCircle16Filled></DismissCircle16Filled>}
        ></Button>
      </div>
      <div className={styles.root}>
        <img src="assets/Benetics_Connect_infographic.png"></img>
        <div className={styles.root}>
          <Subtitle2>
            Achtung: Projektdetails können nach Projekterstellung nicht geändert
            werden. {/* TODO(databurro): i18n */}
          </Subtitle2>
        </div>
        <div>
          {/* TODO(databurro): i18n */}
          <Input
            type="text"
            appearance="filled-darker"
            name="project_id"
            placeholder="Projekt ID*"
            value={id}
            id={projectIdId}
            onChange={(e) => {
              setId(e.target.value);
            }}
          />
        </div>
        {/* TODO(databurro): i18n */}
        <div>
          <Input
            type="text"
            appearance="filled-darker"
            name="project_name"
            placeholder="Projektname*"
            value={name}
            id={projectNameId}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        {/* TODO(databurro): i18n */}
        <div>
          <Input
            type="text"
            appearance="filled-darker"
            name="project_address"
            placeholder="Adresse*"
            value={address}
            id={projectAddressId}
            onChange={(e) => {
              const matches = e.target.value.match(new RegExp(",?([0-9]{4})"));
              if (matches !== null && matches !== undefined) {
                if (matches.length > 0) {
                  // matches[0] is the whole matched string. Take the first group,
                  // at index 1.
                  setPostalCode(matches[1]);
                }
              }
              setAddress(e.target.value);
            }}
          />
        </div>
        {/* TODO(databurro): i18n */}
        <div>
          <Input
            type="text"
            appearance="filled-darker"
            name="project_description"
            placeholder="Beschreibung"
            value={description}
            id={projectDescriptionId}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
        {/* TODO(databurro): i18n */}
        <div>
          <DatePicker
            allowTextInput={true}
            type="text"
            appearance="filled-darker"
            name="project_start_date"
            placeholder="Startdatum aussuchen"
            id={projectStartDateId}
            onSelectDate={setStartDate}
            showGoToToday={true}
          />
        </div>
        {/* TODO(databurro): i18n */}
        <div>
          <DatePicker
            allowTextInput={true}
            type="text"
            appearance="filled-darker"
            name="project_end_date"
            placeholder="Enddatum aussuchen"
            id={projectEndDateId}
            onSelectDate={setEndDate}
            showGoToToday={true}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "space-evenly",
            alignItems: "center",
            alignContent: "stretch",
          }}
        >
          <Button
            appearance="primary"
            size="medium"
            disabled={id === "" || name === "" || address === ""}
            onClick={(e) => {
              handleSubmit(e);
              onSubmitClick();
            }}
          >
            Erstellen
          </Button>
        </div>
      </div>
    </form>
  );
};

export default NewProjectForm;
